<template>
  <div>
    <v-overlay
      v-if="local_modal && $store.state.hasMotors"
      opacity="1"
      color="#A69578"
      style="text-align=center"
    >
      <v-progress-circular indeterminate color="red"></v-progress-circular
      ><br /><strong style="color: red">{{
        $t("keyPick.wait")
      }}</strong></v-overlay
    >
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6">{{ $t("keyPick.title") }}</v-col>
        <v-col cols="3" />
      </v-row>

      <v-row>
        <v-col cols="2" />
        <v-col cols="1"
          ><v-switch
            v-model="confirmKey"
            @click="toggleKey"
            @change="toggleKey"
        /></v-col>
        <v-col cols="7" style="padding: 30px 12px 0 12px">{{
          $t("keyPick.confirmKey")
        }}</v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="1"
          ><v-switch
            v-model="confirmPaper"
            @click="togglePaper"
            @change="togglePaper"
        /></v-col>
        <v-col cols="7" style="padding: 30px 12px 0 12px">{{
          $t("keyPick.confirmPaper")
        }}</v-col>
        <v-col cols="2" />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "KeyDrop",
  data() {
    return {
      confirmKey: false,
      confirmPaper: false,
      local_modal: true,
    };
  },
  beforeMount() {
    if (this.confirmKey) {
      this.$store.commit("enableNext");
    } else {
      this.$store.commit("disableNext");
    }
  },
  mounted() {
    if (this.$store.state.apptId == 0) this.$router.push("/");
    this.$store.commit("keyDropNotKey");
    this.$store.commit("keyDropNotPaper");
    window.setTimeout(() => {
      this.local_modal = false;
    }, 8000);
  },
  methods: {
    toggleKey() {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      if (this.confirmKey) {
        this.$store.commit("keyDropKey");
        this.$store.commit("enableNext");
      } else {
        this.$store.commit("keyDropNotKey");
        this.$store.commit("disableNext");
      }
    },
    togglePaper() {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      if (this.confirmPaper) {
        this.$store.commit("keyDropPaper");
      } else {
        this.$store.commit("keyDropNotPaper");
      }
    },
  },
};
</script>
